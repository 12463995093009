<template>
  <div class="container">
    <div v-if="!notShowHeader.includes($route.name as string)" ref="commonHeader" class="common-header">
      <div class="header">
        <div @click="handleClick('home')">
          <img class="header-logo" :src="logo" alt="logo" />
        </div>
        <div class="header-right">
          <div v-if="avatarUrl && nickName" class="header-right-login">
            <div class="header-right-login-msg">
              <text>{{ nickName }}</text>
              <text>欢迎您</text>
            </div>
            <Popover theme="dark" placement="bottom-end" z-index="10000">
              <div style="padding: 6px">用户ID：{{ authStore.userId }}</div>
              <template #reference>
                <img class="header-right-login-avatar" :src="avatarUrl" alt="avatar" />
              </template>
            </Popover>
          </div>
          <img :src="list" @click="showPopup = !showPopup" />
        </div>
      </div>
      <Popup v-model:show="showPopup" position="top" round :style="{ height: '359px' }">
        <template #default>
          <div class="popup-wrapper">
            <div v-for="(item, index) in popupOptions" :key="index">
              <div class="line" @click="handleClick(item.routeName)">{{ item.title }}</div>
            </div>
            <div class="space">
              <Button round color="#2669B2" @click="userLogin(fullPath)" v-if="!isLogin">登录/注册</Button>
              <Button round color="#2669B2" @click="logout" v-else>退出登录</Button>
            </div>
          </div>
        </template>
      </Popup>
    </div>
    <RouterView
      :class="[notShowHeader.includes($route.name as string) ? 'router-view-learn-content' : 'router-view']"
    />
    <CommonFooter v-if="!notShowFooter.includes($route.name as string)" />
  </div>
</template>

<script setup lang="ts">
import logo from '@/assets/imgs/logo.png';
import list from '@/assets/svgs/riLine-menu-line-1.svg';
import CommonFooter from '@/components/common/CommonFooter.vue';
import { USER_ROLE, useAuthStore } from '@/stores';
import { getCookie, parseJwt, userLogin } from '@/utils';
import { logout } from '@/utils/auth';
import { storeToRefs } from 'pinia';
import { Button, Popover, Popup } from 'vant';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const authStore = useAuthStore();
const { isLogin, avatarUrl, nickName } = storeToRefs(authStore);
const router = useRouter();
const route = useRoute();
const fullPath = ref<string>(route.fullPath);
const showPopup = ref(false);
const commonHeader = ref<HTMLDivElement>();
const popupOptions = ref([
  {
    title: 'AI活动',
    routeName: 'activity',
  },
  {
    title: 'AI学习',
    routeName: 'learn',
  },
  {
    title: 'AI实践',
    routeName: 'practice',
  },
  {
    title: 'AIGC',
    routeName: 'aigc',
  },
  {
    title: '关于我们',
    routeName: 'about',
  },
]);
const notShowFooter = ref<string[]>(['activity-detail', 'invite-recruit', 'invite-poster', 'learn-content']);
const notShowHeader = ref<string[]>(['learn-content']);

async function updateLoginStatus() {
  const jwt = getCookie('DATAWHALE_WEB_TOKEN');
  if (jwt) {
    const jwtPayload = parseJwt(jwt);
    if (Date.now() / 1000 > jwtPayload.exp) {
      await logout();
    } else {
      authStore.setAuth({
        isLogin: true,
        avatarUrl: jwtPayload.avatarUrl,
        nickName: jwtPayload.nickName,
        role: jwtPayload.isAdmin === 'false' ? USER_ROLE.USER : USER_ROLE.ADMIN,
        userId: Number(jwtPayload.sub),
        showLoginPanel: false,
      });
    }
  }
}

const handleClick = (name: string) => {
  router.push({ name });
  showPopup.value = false;
};

onMounted(() => {
  updateLoginStatus();
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;

  .common-header {
    .header {
      z-index: 9999;
      position: fixed;
      width: 100%;
      height: 56px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px 0;
      box-sizing: border-box;

      &-logo {
        width: 155px;
        height: 25px;
      }

      &-right {
        display: flex;
        align-items: center;

        &-login {
          display: flex;
          align-items: center;

          &-msg {
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            font-weight: 500;
          }

          &-avatar {
            width: 30px;
            height: 30px;
            border-radius: 40px;
            background-color: pink;
            margin-left: 8px;
            margin-right: 11px;
          }
        }
      }
    }

    .van-popup {
      background-color: transparent;
      margin-top: 56px;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .popup-wrapper {
        z-index: 999;
        width: 100%;
        position: fixed;
        box-sizing: border-box;
        padding: 32px 37px 57px;
        background-color: #fff;
        border-radius: 0 0 20px 20px;

        .line {
          margin-bottom: 28px;
          color: rgba(27, 31, 51, 1);
          font-size: 16px;
          font-family: PingFangSC-medium;
        }

        .space {
          display: flex;
          justify-content: center;

          .van-button {
            margin-top: 14px;
            width: 120px;
            height: 36px;
            border-radius: 20px;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            font-family: PingFangSC-medium;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.router-view {
  margin-top: 56px;
}

.router-view-learn-content {
  margin-top: 0;
}
</style>
